import React, { useState } from "react";
import {
  Container,
  CssBaseline,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Input,
  Typography,
} from "@mui/material";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import theme from "./theme.js";
import NavBar from "./NavBar.js";

function SalaryCalculator() {
  const [basicSalaryValue, setBasicSalaryValue] = useState("");
  const [allowanceValue, setAllowanceValue] = useState("");
  const handleInputChange = (event) => {
    if (event.target.name === "basic-salary") {
      setBasicSalaryValue(event.target.value);
    }
    if (event.target.name === "allowance-salary") {
      setAllowanceValue(event.target.value);
    }
  };
  var fixedSalary = +basicSalaryValue + +allowanceValue; // (+) operator typecasts the string to number
  var taxSlab1 = 0; // 0 to 4 Lakh
  var taxSlab2 =
    fixedSalary >= 800000 || (fixedSalary > 400000 && fixedSalary < 800000)
      ? 20000
      : 0; // 5%
  var taxSlab3 =
    fixedSalary >= 1200000 || (fixedSalary > 800000 && fixedSalary < 1200000)
      ? 40000
      : 0; // 10%
  var taxSlab4 =
    fixedSalary >= 1600000 || (fixedSalary > 1200000 && fixedSalary < 1600000)
      ? 60000
      : 0; // 15%
  var taxSlab5 =
    fixedSalary >= 2000000 || (fixedSalary > 1600000 && fixedSalary < 2000000)
      ? 80000
      : 0; // 20%
  var taxSlab6 =
    fixedSalary >= 2400000 || (fixedSalary > 2000000 && fixedSalary < 2400000)
      ? 100000
      : 0; // 25%
  var taxSlab7 = Math.round(
    fixedSalary > 2400000 ? ((fixedSalary - 2400000 - 75000) * 30) / 100 : 0
  ); // 30%
  var cess = Math.round(
    ((taxSlab1 +
      taxSlab2 +
      taxSlab3 +
      taxSlab4 +
      taxSlab5 +
      taxSlab6 +
      taxSlab7) *
      4) /
      100
  );
  var totalTax =
    taxSlab1 +
    taxSlab2 +
    taxSlab3 +
    taxSlab4 +
    taxSlab5 +
    taxSlab6 +
    taxSlab7 +
    cess;
  var employeePF = Math.round((basicSalaryValue * 12) / 100);
  var monthlyInHandSalary = Math.round(
    (fixedSalary - totalTax - employeePF - 2400) / 12
  );

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <NavBar hideNavBarLinks={true} />
        <Container>
          <Typography
            sx={{
              typography: {
                xs: "h5",
                sm: "h5",
                md: "h4",
                lg: "h4",
                xl: "h4",
              },
            }}
            paragraph
            paddingTop={3}
            textAlign={"center"}
          >
            Monthly in-hand salary calculator
          </Typography>
          <Typography
            sx={{
              typography: {
                xs: "h6",
                sm: "h6",
                md: "h5",
                lg: "h5",
                xl: "h5",
              },
            }}
            paragraph
            textAlign={"center"}
          >
            For new regime only
          </Typography>
          <Grid container justifyContent={"center"}>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="basic-salary">Basic Salary</InputLabel>
                <Input
                  id="basic-salary"
                  aria-describedby="basic-salary-helper"
                  name="basic-salary"
                  value={basicSalaryValue}
                  onChange={handleInputChange}
                />
                <FormHelperText id="basic-salary-helper">
                  Part of the fixed compensation.
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container justifyContent={"center"}>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="allowance-salary">Allowance</InputLabel>
                <Input
                  id="allowance-salary"
                  aria-describedby="allowance-salary-helper"
                  name="allowance-salary"
                  value={allowanceValue}
                  onChange={handleInputChange}
                />
                <FormHelperText id="allowance-salary-helper">
                  Also part of the fixed compensation.
                  <br />
                  Sum up all your allowances (Dearness, HRA, LTA, etc.)
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container justifyContent={"center"}>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl margin="normal" disabled fullWidth>
                <InputLabel htmlFor="inhand-salary">Monthly salary</InputLabel>
                <Input
                  id="inhand-salary"
                  aria-describedby="inhand-salary-helper"
                  name="inhand-salary"
                  value={monthlyInHandSalary > 0 ? monthlyInHandSalary : ""}
                />
                <FormHelperText id="inhand-salary-helper">
                  After tax deduction, of course.
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default SalaryCalculator;
